@use '@angular/material' as mat;

@include mat.core();

$primary: mat.define-palette(mat.$indigo-palette);
$accent: mat.define-palette(mat.$pink-palette);

$theme: mat.define-light-theme((
        color: (
                primary: $primary,
                accent: $accent,
        ),
));

$color-primary: mat.get-color-from-palette($primary, default);
$color-background: #f1f1f1;
$color-text-dark: #808080;
$font-family: Roboto, "Helvetica Neue", sans-serif;
$box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
0px 4px 5px 0px rgba(0, 0, 0, 0.14),
0px 1px 10px 0px rgba(0, 0, 0, 0.12);
$form-field-margin-bottom: 22px;
