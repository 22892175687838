// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$kaizen-primary: mat.define-palette(mat.$indigo-palette);
$kaizen-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$kaizen-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$kaizen-theme: mat.define-light-theme((color: (primary: $kaizen-primary,
        accent: $kaizen-accent,
        warn: $kaizen-warn,
      ),
      typography: mat.define-typography-config(),
      density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($kaizen-theme);

@import "./styles/variables";

* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: $font-family;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: $color-background;
}

a {
  text-decoration: none;
  color: $color-primary;

  &:hover {
    text-decoration: underline;
  }
}

form {
  container-type: inline-size;
  container-name: formContainer;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    gap: 1rem;
    width: 100%;

    .col {
      flex: 1 1 0;

      >* {
        width: 100%;
      }
    }
  }
}

@container formContainer (max-width: 705px) {
  .row {
    flex-direction: column;
    gap: 0 !important;
  }
}

fieldset {
  padding: 1rem;
  border: 2px solid $color-primary;
  border-radius: 4px;

  legend {
    padding: 0 0.5rem;
    color: $color-primary;
  }
}

/* Utility Classes -- Starts */
.primary {
  color: $color-primary;
}

.clickable {
  cursor: pointer;
}

.a-center {
  justify-content: center;
}

.m-0 {
  margin: 0 !important;
}

.m-tp-1 {
  margin-top: 1rem;
}

.invalid {
  color: var(--mat-form-field-error-text-color);
  border-color: var(--mat-form-field-error-text-color) !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.38;
}

.input-sm {
  padding: 0.5rem;
  border: 1px solid $color-background;
  border-radius: 4px;
  font-size: 1rem;
  min-width: 15rem;
  max-width: 100%;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border: 2px solid $color-primary;
    padding: calc(0.5rem - 1px);
  }
}

.success {
  color: green;
}

.pd-1 {
  padding: 1rem;
}

.info-text {
  color: $color-text-dark;
  font-size: 12px;
}

.hidden {
  display: none;
}

.width-100 {
  width: 100%;
}

/* Utility Classes -- Ends */

/* Angular Material CSS Override - Starts */
.mat-mdc-card {
  box-shadow: $box-shadow !important;
}

.form-field-sm {
  .mdc-text-field {
    padding: 0 8px;
  }

  .mat-mdc-form-field-infix {
    min-height: 40px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

.mdc-form-field--align-end>label {
  padding-right: 1rem !important;
}

.mdc-tab-indicator--active {
  background-color: $color-primary !important;

  .mdc-tab__text-label {
    color: #fff !important;
  }
}

.mdc-tab-indicator__content--underline {
  opacity: 1 !important;
}

/* Angular Material CSS Override - Ends */

/* Spinner - Starts */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: $color-primary;
  animation: spinner 0.8s linear infinite;
}

/* Spinner - Ends */

/* PDF Viewer - Starts */
ngx-extended-pdf-viewer {
  #toolbarViewer {
    padding: 0 !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  // #mainContainer {
  //   margin-bottom: 5rem;
  // }
}

/* PDF Viewer - Ends */

/* Custom Fonts - Starts */
@font-face {
  font-family: 'arty-signature';
  src: url('assets/fonts/arty-signature/Arty\ Signature.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dr-sugiyama';
  src: url('assets/fonts/dr-sugiyama/DrSugiyama-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'may-queen';
  src: url('assets/fonts/may-queen/mayqueen.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'monsieur-la-doulaise';
  src: url('assets/fonts/monsieur-la-doulaise/MonsieurLaDoulaise-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mr-dafoe';
  src: url('assets/fonts/mr-dafoe/MrDafoe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mr-de-haviland';
  src: url('assets/fonts/mr-de-haviland/MrDeHaviland-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mrs-saint-delafield';
  src: url('assets/fonts/mrs-saint-delafield/MrsSaintDelafield-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pwsignaturetwo';
  src: url('assets/fonts/pwsignaturetwo/PWSignaturetwo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sarina';
  src: url('assets/fonts/sarina/Sarina-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'the-suavity';
  src: url('assets/fonts/the-suavity/The\ Suavity.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Custom Fonts - Ends */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}